<template>
  <div class="purchase"
       :style="{'background': iconClick == 'first' ? '#FFDAB3' : iconClick == 'second' ? '#F4F4F4' : '#F9F9F9'}">
    <!--    1元抢购-->
    <div v-if="iconClick == 'first'">
      <div class="top">
        <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/rush_purchase_top.png"/>
        <div class="back-icon">
          <van-icon name="arrow-left" @click="back"/>
        </div>
      </div>
      <div class="fixed-button">
        <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/phone_one_rule.png" @click="handleJumpRule">
      </div>
      <div class="purchase-video">
        <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_coupon.png"/>
        <div v-if="!isLottery" class="purchase-video-inner">
          <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/rush_purchase_step_2.png">
          <div v-if="!playVideo" class="video-click" @click="handleJumpVideo">
            <p>如何使用1元抢</p>
            <van-icon name="play-circle-o"/>
          </div>
          <div v-else>
            <van-dialog v-model="playVideo" :showConfirmButton="false" :close-on-click-overlay="true"
                        style="background-color: transparent;">
              <iframe src="https://cdn.cailu88.com/jingxuanshi/video/luckycode_intro.mp4" frameborder='0'
                      allow='autoplay;encrypted-media' allowfullscreen
                      style='width:100%;height:200px;'>
              </iframe>
            </van-dialog>
          </div>
        </div>
        <div v-else class="purchase-video-inner">
          <div class="lottery-code">
            <p>今日开奖码</p>
            <div>
              <p>活动说明</p>
              <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_video_icon.png">
            </div>
          </div>
          <div class="reward-ball">
            <template v-for="(item, index) in rewardBall">
              <div :key="index">
                <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_reward_ball.png">
                <p>{{ item }}</p>
              </div>
            </template>
          </div>
          <div class="winning-detail">
            <p @click="handleAwardDetail('')">开奖详情</p>
            <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_winning_detail.png" alt="">
          </div>
        </div>
      </div>
      <div class="refund">
        <p>超级抢购 不中奖100%退款</p>
        <div class="goods-list">
          <template v-for="item in goodsList">
            <div class="goods-wrap">
              <div class="goods-pic">
                <img v-lazy="item.itemPic">
                <div class="open-time">
                  <p>明日10:00开奖</p>
                </div>
              </div>
              <div class="goods-info">
                <p class="goods-title">{{ item.itemTitle }}</p>
                <p class="goods-price">商品价值 ¥{{ item.itemPrice }}</p>
                <van-progress :percentage="item.percent" color="#FF3B3C"></van-progress>
                <div class="amount">
                  <p>限量<span>{{ item.volumeLimit }}</span>份</p>
                  <p>剩余<span>{{ item.remainVolume }}</span>份</p>
                </div>
                <div v-if="item.showStatus == 0" class="join-button" @click="handleShowDialog('one', item.id)">
                  <p>¥<span>{{ item.costAmount }}</span></p>
                  <p>立即参与</p>
                </div>
                <div v-else-if="item.showStatus == 1" class="joined-button" @click="handleShowDialog()">
                  <p>已参与</p>
                </div>
                <div v-else-if="item.showStatus == 2" class="joined-button" @click="handleShowDialog()">
                  <p>已抢光，每天0点开抢</p>
                </div>
              </div>
            </div>
          </template>
        </div>
        <van-divider
          :style="{ color: '#C99040', borderColor: '#C99040', padding: '0 19px' }"
        >到底啦
        </van-divider>
      </div>
    </div>
    <!--    抢购记录-->
    <div v-else-if="iconClick == 'second'">
      <Head :head-info="headInfo" @headBack="handleReload"></Head>
      <div style="text-align: center">
        <div v-if="purchaseGoodsList.length > 0">
          <template v-for="item in purchaseGoodsList">
            <div class="purchase-goods-list" :key="item.id">
              <div @click="handleAwardDetail(item.luckyDate)">
                <div class="head">
                  <span>{{ item.luckyDateValue }}期</span>
                  <span>{{ item.status == 1 ? drawTime(item) : `开奖码 ${item.luckyCode}` }}</span>
                </div>
                <div class="content">
                  <img :src="item.itemPic">
                  <div class="info">
                    <p class="title">{{ item.itemTitle }}</p>
                    <p>商品价值<span></span> ￥{{ item.itemPrice }}</p>
                    <p>抢购金额<span></span> ￥{{ item.costAmount }}</p>
                    <p>参与时间<span></span> {{ item.createTime }}</p>
                  </div>
                </div>
                <div class="code-list">
                  <p>我的抽奖码</p>
                  <div>
                    <template v-for="(item_c, index_c) in item.codeList">
                      <div class="code-wrap" :key="index_c">
                        <p>{{ item_c }}</p>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <div class="footer-button" @click="handleRecordClick(item)">
                <p
                  :class="item.status == 1 && (item.codeList.length < item.codeLimit) ? 'button-more' : item.status == 3 ? 'button-earn' : item.status == 2 ? 'button-recharge' : item.status == 6 ? 'button-recharge' :  'normal'">
                  {{
                    item.status == 1 && (item.codeList.length < item.codeLimit) ? '再拿一个' : item.status == 3 ? '立即领奖' : item.status == 2 ? '申请退款' : item.status == 6 ? '我的红包' : item.status == 4 ? '已领奖' : item.status == 5 ? '已退款' : item.status == 7 ? '已过期' : ''
                  }}</p>
              </div>
            </div>
          </template>
        </div>
        <div v-else class="purchase-default">
          <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_goods_none.png">
          <p>暂无抢购记录</p>
          <p class="join-activity" @click="handleVIPActivity(1)">立即参与活动</p>
        </div>
      </div>
    </div>
    <!--    VIP福利-->
    <div v-else-if="iconClick == 'third'">
      <Head :head-info="headInfo" @headBack="handleReload"></Head>
      <div class="activity-list">
        <div @click="handleVIPActivity(1)">
          <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/welfare_one.jpg" alt="">
        </div>
        <div @click="handleVIPActivity(2)">
          <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/welfare_zero.jpg" alt="">
        </div>
        <div @click="handleVIPActivity(3)">
          <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/welfare_phone_2.jpg" alt="">
        </div>
        <div @click="handleVIPActivity(4)">
          <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/welfare_new_2.jpg" alt="">
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-icon" :style="{'color': iconClick == 'first' ? '#C99040' : '#888888'}"
           @click="handleChangeTab(1)">
        <img class="first-icon" v-if="iconClick == 'first'"
             src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_bottom_icon_active.png">
        <img class="first-icon" v-else src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_bottom_icon.png">
        <p>1元抢购</p>
      </div>
      <div class="footer-icon" :style="{'color': iconClick == 'second' ? '#C99040' : '#888888'}"
           @click="handleChangeTab(2)">
        <img class="second-icon" v-if="iconClick == 'second'"
             src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_bottom_record_active.png">
        <img class="second-icon" v-else
             src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_bottom_record.png">
        <p>抢购记录</p>
      </div>
      <div class="footer-icon" :style="{'color': iconClick == 'third' ? '#C99040' : '#888888'}"
           @click="handleChangeTab(3)">
        <img class="third-icon" v-if="iconClick == 'third'"
             src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_bottom_privilege_active.png">
        <img class="third-icon" v-else
             src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_bottom_privilege.png">
        <p>VIP福利</p>
      </div>
    </div>
    <div v-if="isScroll" class="fixed-top" @click="handleScrollToTop">
      <van-icon name="back-top"/>
      <p>顶部</p>
    </div>
    <!--    弹窗-->
    <van-dialog v-model="dialogVisible" :showConfirmButton="false" style="background-color: transparent;">
      <div v-if="dialogType == 'dilate'" class="dilate-packet">
        <img class="packet-title" src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_dilate_text.png">
        <p>仅需1元，至多膨胀1000倍！</p>
        <img class="packet-coupon" src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_dilate_packet.png">
        <div class="packet-button">
          <div class="button" @click="handleDialogSubmit">
            <p>立即领取</p>
            <van-icon name="play"/>
          </div>
          <p @click="handleCloseDialog('dilate')">我不要了，残忍离开</p>
        </div>
      </div>
      <div v-else-if="dialogType == 'dilateRefund'" class="dilate-packet">
        <img class="packet-title"
             src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_dilate_text_refund.png">
        <img class="packet-coupon"
             src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_dilate_packet_refund.png">
        <div class="packet-text">
          <p>可将未中奖的1元</p>
          <p>立即膨胀为30元购物红包</p>
        </div>
        <div class="packet-button">
          <div class="button" @click="handleDialogSubmit">
            <p>立即领取</p>
            <van-icon name="play"/>
          </div>
          <p @click="handleCloseDialog('refund')">不要福利，立即退款</p>
        </div>
      </div>
      <div v-else-if="dialogType == 'lottery'" class="open-lottery">
        <img :src="`https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_open_code${openCodeType}.png`">
        <div v-if="openCodeType != 3" class="lottery-content">
          <p>明日10:00开奖，不要错过大奖哦！</p>
          <div class="reward-ball">
            <template v-for="(item, index) in lotteryInfo.code">
              <div :key="index">
                <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_reward_ball.png">
                <p>{{ item }}</p>
              </div>
            </template>
          </div>
          <van-progress :percentage="lotteryInfo.luckyRate" :pivot-text="`中奖概率${lotteryInfo.luckyRate}%`"
                        color="#FF3B3C"></van-progress>
          <div class="lottery-button">
            <p>{{ lotteryInfo.codeCount == lotteryInfo.codeLimit ? '本次参与的抽奖码已达到上限' : '获取更多抽奖码，增加中奖概率！' }}</p>
            <div class="button" @click="handleCodeForFree(lotteryInfo.buttonText)">
              <p>{{ lotteryInfo.buttonText }}</p>
            </div>
          </div>
        </div>
        <div v-if="openCodeType == 3" class="lottery-content">
          <p>抽奖码越多，中奖概率越高！</p>
          <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_more_code_2.png">
          <p class="code-count">{{ lotteryInfo.codeLimit - lotteryInfo.codeCount || 5 }}</p>
          <van-progress :percentage="lotteryInfo.luckyRate" :pivot-text="`中奖概率${lotteryInfo.luckyRate}%`"
                        color="#FF3B3C"></van-progress>
          <div class="lottery-button">
            <div class="button" @click="handleCodeForFree('')">
              <p>免费获取抽奖码</p>
            </div>
            <p class="leave" @click="handleCloseDialog('none')">忍痛离开</p>
          </div>
        </div>
        <div v-if="openCodeType != 3" class="lottery-close">
          <van-icon name="close" @click="handleCloseDialog('lottery')"/>
        </div>
      </div>
      <div v-else class="join-activity">
        <div v-if="dialogType == 'one'" class="inner">
          <div class="activity-info">
            <p class="activity-title">立即参加活动</p>
            <p>仅需9.9元即可无限期参与1元抢购活动</p>
          </div>
          <div class="activity-pic">
            <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_dialog_one.png"/>
          </div>
          <van-button @click="handlePay(5)">立即付款</van-button>
          <p @click="handleCloseDialog('one')">放弃1元抽奖福利，残忍离开</p>
        </div>
        <div v-else-if="dialogType == 'zero'" class="inner">
          <div class="activity-info">
            <p class="activity-title">你还有1次免费领机会未使用</p>
            <p>好物免费领，0元包邮到家</p>
          </div>
          <div class="activity-pic">
            <img src="https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_dialog_zero.png"/>
          </div>
          <van-button @click="openFreeActivity()">现在就去领取</van-button>
          <p @click="handleCloseDialog('zero')">我不要了，残忍离开</p>
        </div>
        <div v-else-if="dialogType == 'award'" class="inner">
          <div class="activity-info">
            <p class="activity-title">立即领奖</p>
            <p>扫码添加专属微信客服</p>
            <p>即可领取奖品</p>
          </div>
          <div class="award-pic">
            <img src=""/>
          </div>
          <p class="service-time">客服服务时间：9:00-24:00</p>
          <van-button @click="">保存并打开微信</van-button>
        </div>
        <div v-else-if="dialogType == 'refundSuccess'" class="inner small-inner">
          <div class="activity-info">
            <p class="activity-title">退款成功</p>
            <p>继续参与活动</p>
            <p>中奖概率将会持续提升哦！</p>
          </div>
          <van-button @click="handleReload">查看其它1元抢购福利</van-button>
        </div>
        <div v-else-if="dialogType == 'privilege'" class="inner">
          <div class="privilege-open">
            <p class="title">9.9元开通鲸选省钱卡会员</p>
            <div class="icon-list">
              <template v-for="item in privilegeList">
                <div :key="item.code">
                  <img :src="`https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_privilege_${item.pic}.png`">
                  <p>{{ item.desc }}</p>
                </div>
              </template>
            </div>
            <van-button @click="handlePay(5)">立即开通</van-button>
            <p @click="handleCloseDialog('privilege')">更有超多会员专属特权等你体验</p>
          </div>
        </div>
        <div v-else-if="dialogType == 'privilegeClose'" class="inner">
          <div class="privilege-open">
            <p class="title">9.9元开通鲸选省钱卡会员</p>
            <div class="icon-list">
              <template v-for="item in privilegeList">
                <div :key="item.code">
                  <img :src="`https://cdn.cailu88.com/jingxuanshi/phoneRecharge/purchase_privilege_${item.pic}.png`">
                  <p>{{ item.desc }}</p>
                </div>
              </template>
            </div>
            <van-button @click="handlePay(5)">立即开通</van-button>
            <p @click="handleCloseDialog('privilegeClose')">更有超多会员专属特权等你体验</p>
          </div>
        </div>
      </div>
    </van-dialog>
    <Loading :click="click"></Loading>
  </div>
</template>
<script>
import Vue from 'vue'
import { Progress, Divider, Lazyload, Icon, Dialog, Button } from 'vant'
import { timeFormat } from '@/utils/utils'
import baseUrl from '@/utils/baseUrl'
import Loading from '../../components/loading.vue'
import Head from '../../components/fullWebviewHead.vue'

const api = require('@/utils/api').api

Vue.use(Progress)
Vue.use(Divider)
Vue.use(Icon)
Vue.use(Dialog)
Vue.use(Button)
Vue.use(Lazyload, {
  lazyComponent: true,
})
export default {
  name: 'rushPurchase',
  data () {
    return {
      //  头部导航栏
      headInfo: {},
      //  抢购商品列表
      goodsList: [],
      //  抢购商品id
      goodsId: '',
      //  底部icon
      iconClick: 'first',
      //  加载中
      click: true,
      //  是否开奖
      isLottery: false,
      //  开奖码
      rewardBall: [],
      //  滚动到顶部图标显示
      isScroll: false,
      scroll: '',
      height: '',
      //  商品总数
      totalCount: 0,
      //  抢购记录总数
      recordTotalCount: 0,
      //  弹窗显隐
      dialogVisible: false,
      //  弹窗类型
      //  dialogType： dilate 膨胀1000 / dilateRefund 膨胀30 / lottery 开奖码 /  one 9.9元获取一元购权限 / zero 去0元购 / award 中奖领奖 / refundSuccess 退款成功
      dialogType: '',
      //  开奖弹窗类型
      openCodeType: 1,
      //  开奖弹窗信息
      lotteryInfo: {},
      //  抢购记录列表
      purchaseGoodsList: [],
      //  抢购记录id
      recordId: '',
      //  抽奖列表页码
      page: 0,
      pageSize: 10,
      //  抢购记录页码
      recordPage: 0,
      recordPageSize: 10,
      // 省钱卡特权
      privilegeList: [],
      // 一元购特权
      payOnePrivilege: false,
      //  视频播放
      playVideo: false
    }
  },
  components: {
    Loading,
    Head
  },
  created () {
    this.privilegeList = [
      {
        code: 1,
        pic: 'goods',
        desc: '每日一元抢购'
      },
      {
        code: 2,
        pic: 'packet',
        desc: '千元补贴红包'
      },
      {
        code: 3,
        pic: 'phone',
        desc: '领100元话费券'
      }
    ]
  },
  mounted () {
    // 友盟统计
    const scriptSta = document.createElement('script')
    scriptSta.src = 'https://v1.cnzz.com/z_stat.php?id=1279885565&web_id=1279885565'
    scriptSta.language = 'JavaScript'
    document.body.appendChild(scriptSta)
    const index = (this.$route.hash).includes('vip') ? 3 : localStorage.getItem('navIndex')
    this.handleChangeTab(index, '1')
    this.showTitleBar(false)
    window.addEventListener('scroll', this.lazyLoading) // 滚动到底部，再加载的处理事件
    this.handleCheckPopCode()
    this.handleCheckIsOpenDraw()
    this.handleCheckRight()
    //  test
    //  this.outSideCommunication({type:'one'})
  },
  computed: {
    // computed：注意不能直接在()后面加参数，没效果（应该是vue不支持），应该以JavaScript闭包的形式
    drawTime () {
      return function (data) {
        const nowTime = timeFormat(new Date()).slice(0, 10)
        const pubTime = timeFormat(data.pubTime).slice(0, 10)
        return pubTime > nowTime ? `明日${data.drawTime}开奖` : `今日${data.drawTime}开奖`
      }
    }
  },
  methods: {
    /*
    改变状态栏颜色
    */
    handleChangeColor (bgColor, textColor) {
      this.$h5AppLink('updateStyle', {
        statusBarBgColor: bgColor,
        statusBarTextColor: textColor
      })
    },
    /*
    显示获隐藏导航标题栏
    */
    showTitleBar (showTitleBar) {
      this.$h5AppLink('showTitleBar', {
        showTitleBar: showTitleBar
      })
    },
    /*
    显示规则
     */
    handleJumpRule () {
      this.$router.push({ name: 'purchaseRules' })
    },

    /*
    打开全屏的Webview（无原生导航栏）
    */
    openFullWebview (param) {
      this.$h5AppLink('openFullWebview', param)
    },

    /*
    */
    outSideCommunication (param) {
      this.dialogVisible = true
      this.dialogType = param.type
    },

    /*
    判断开奖date
    */
    handleCheckIsOpenDraw () {
      const now = timeFormat(new Date()).slice(11, 13)
      const yest = timeFormat(new Date() - 3600 * 1000 * 24).slice(0, 10)
      if (now >= 10 && now < 13) {
        this.handleGetLuckyDraw(yest)
      }
    },

    /*
    切换底部icon
     */
    handleChangeTab (index, times) {
      let bgColor = ''
      let textColor = ''
      let hash = this.$route.hash
      this.headInfo = {}
      this.page = 0
      this.recordPage = 0
      this.iconClick = index == 2 ? 'second' : index == 3 ? 'third' : 'first'
      if (hash.includes('vip') && times == 1) {
        window.location.hash = hash.slice(4)
      }
      if (index == 1 || index == null) {
        bgColor = '#EF7B3F'
        textColor = null
        this.handleGetLuckyList()
      } else if (index == 2) {
        this.headInfo.title = '抢购记录'
        this.handleGetRecordList()
        bgColor = 'white'
        textColor = 'black'
      } else if (index == 3) {
        this.headInfo.title = 'VIP超级福利'
        bgColor = 'white'
        textColor = 'black'
      }
      // 改变状态栏颜色
      if (this.$store.state.isAndroidApp) {
        this.handleChangeColor(bgColor, textColor)
      }
      if (localStorage.getItem('navIndex') > 0) {
        localStorage.removeItem('navIndex')
      }
      this.handleScrollToTop()
    },

    /*
    滚动加载商品
     */
    lazyLoading () {
      //  获取 可视区高度`、`滚动高度`、`页面高度`
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const clientHeight = document.documentElement.clientHeight
      const scrollHeight = document.documentElement.scrollHeight
      this.isScroll = scrollTop === 0 ? false : true
      this.scroll = scrollHeight
      this.height = scrollTop + clientHeight
      if (scrollTop + clientHeight + 10 >= scrollHeight) { // 滚动到底部，逻辑代码
        //  事件处理
        if (this.goodsList.length < this.totalCount && this.totalCount !== 0 && this.iconClick == 'first') {
          this.handleGetLuckyList('up')
        }
        if (this.purchaseGoodsList.length < this.recordTotalCount && this.recordTotalCount !== 0 && this.iconClick == 'second') {
          this.handleGetRecordList('up')
        }
      }
    },

    /*
    滚动到顶部
     */
    handleScrollToTop () {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: 'smooth'
      })
    },

    /*
     * 显示弹窗（一元抢购商品）
     */
    handleShowDialog (type, id) {
      this.goodsId = id
      switch (type) {
        case 'one':
          if (this.payOnePrivilege) {
            this.handlePay(7)
          } else {
            this.dialogType = type
            this.dialogVisible = true
          }
          break
      }
    },

    /*
    查看活动权益
    */
    handleCheckRight () {
      this.$axios({
        url: api.luckyDrawRights,
        method: 'get',
        params: {}
      }).then(res => {
        this.payOnePrivilege = res.data.data.drawAccess
      }).catch(err => {
        this.isLogin()
        console.info(err)
      })
    },

    /*
    抽奖码弹窗按钮事件
    */
    handleCodeForFree (value) {
      if (value === '继续抢购其他福利') {
        this.dialogVisible = false
      } else {
        this.handleWatchVideo()
      }
    },

    /*
      0元购现在去领
     */
    openFreeActivity () {
      // 0元购现在去领
      this.$h5AppLink('openFreeActivity', {
        id: 2
      })
    },

    /*
      立即支付
     */
    handlePay (bizType) {
      if (!this.click) {
        return false
      }
      this.click = false
      this.$axios({
        url: api.wxPay,
        method: 'post',
        params: {
          tradeType: 4,
          attach: bizType == 7 ? this.goodsId : '',
          bizType: bizType,
          platform: 1
        }
      }).then(res => {
        this.click = true
        this.$router.push({ name: 'payConfirm' })
        window.location.href = res.data.data.mwebUrl
      }).catch(err => {
        this.click = true
        this.isLogin()
        console.info(err)
      })
    },

    /*
    关闭弹窗
     */
    handleCloseDialog (type) {
      if (type === 'one') {
        this.dialogVisible = false
        this.handlePreBack()
        // setTimeout(() => {
        //   this.dialogType = 'zero'
        //   this.dialogVisible = true
        // }, 500)
      } else if (type === 'refund') {
        this.$axios.post(api.recordRefund, {
          id: this.recordId || this.lotteryInfo.recordId
        }).then(res => {
          this.dialogVisible = false
          setTimeout(() => {
            this.dialogType = 'refundSuccess'
            this.dialogVisible = true
          }, 1000)
        }).catch(err => {
          console.info(err)
          this.isLogin()
        })
      } else if (this.lotteryInfo.codeCount < this.lotteryInfo.codeLimit && type === 'lottery') {
        this.dialogVisible = false
        setTimeout(() => {
          this.openCodeType = 3
          this.dialogType = 'lottery'
          this.dialogVisible = true
        }, 1000)
      } else if (type === 'dilate' || type === 'privilegeClose') {
        this.$h5AppLink('closePage', {})
      } else if (type === 'zero') {
        this.handlePreBack()
      } else {
        this.dialogVisible = false
      }
    },

    /*
    开奖详情
     */
    handleAwardDetail (date) {
      this.$router.push({
        name: 'awardDetail',
        query: {
          token: localStorage.getItem('token'),
          luckyDate: date ? timeFormat(date).substr(0, 10) : '',
        }
      })
      localStorage.setItem('navIndex', date ? '2' : '1')
    },
    /*
    查看视频
    */
    handleWatchVideo (id) {
      this.$h5AppLink('playAdVideo', {
        type: 0,
        bizType: 1,
        bizId: id || this.lotteryInfo.recordId
      })
    },

    /*
    查看中奖码
     */
    handleGetLuckyDraw (date) {
      this.$axios({
        url: api.winningCode,
        method: 'get',
        params: {
          date: date
        }
      }).then(res => {
        let data = res.data.data,
          time = ''
        this.rewardBall = data.codeData.code.split('')
        time = timeFormat(data.codeData.luckyDate).slice(8, 10)
        this.isLottery = (timeFormat(data.codeData.luckyDate) > new Date()) && !date.codeData.hasPub && time >= 10 && time < 13 ? false : true
      }).catch(err => {
        console.info(err)
      })
    },

    /*
    进入一元购活动页，查询需要弹出的抽奖码
     */
    handleCheckPopCode () {
      this.$axios(api.drawPopCode, {}).then(res => {
        let result = res.data.data
        if (result.needPop && (result.codeCount <= result.codeLimit)) {
          this.dialogType = 'lottery'
          this.dialogVisible = true
          this.openCodeType = result.codeCount === 1 ? 1 : 2
          result.code = result.code.split('')
          result.luckyRate = (result.luckyRate * 100).toFixed(2)
          result.buttonText = result.codeCount === 1 ? '免费更多抽奖码' : result.codeCount === result.codeLimit ? '继续抢购其他福利' : '再次获取抽奖码'
          this.lotteryInfo = result
          this.handlePopUpdate()
        }
      }).catch(err => {
        console.info(err)
      })
    },
    /*
      弹出抽奖码之后，更新弹出标记
    */
    handlePopUpdate () {
      this.$axios.post(api.popUpdate, {
        id: this.lotteryInfo.codeId
      }).then(res => {
      }).catch(err => {
        console.info(err)
      })
    },
    /*
    查询抽奖商品列表
     */
    handleGetLuckyList (type) {
      if (!this.click && type) {
        return false
      }
      this.page++
      this.click = false
      this.$axios.post(api.luckyGrawGoodsList, {
        page: this.page,
        pageSize: this.pageSize
      }).then(res => {
        this.goodsList = type == 'up' ? [...this.goodsList, ...res.data.data.data] : res.data.data.data
        this.totalCount = res.data.data.totalCount
        this.goodsList ? this.goodsList.map(item => {
          item.pubTime = item.pubTime ? timeFormat(item.pubTime) : 0
          item.drawTime = item.pubTime.slice(11, 16)
          item.percent = item.volumeLimit ? (item.remainVolume / item.volumeLimit).toFixed(2) * 100 : 0
        }) : []
        this.click = true
      }).catch(err => {
        console.info(err)
        this.isLogin()
        this.click = true
      })
    },

    /*
    查看抢购记录
     */
    handleGetRecordList (type) {
      if (!this.click && type) {
        return false
      }
      this.recordPage++
      this.click = false
      this.$axios.post(api.purchaseRecord, {
        data: {
          page: this.recordPage,
          pageSize: this.recordPageSize
        }
      }).then(res => {
        this.purchaseGoodsList = type == 'up' ? [...this.purchaseGoodsList, ...res.data.data.data] : res.data.data.data
        this.recordTotalCount = res.data.data.totalCount
        this.purchaseGoodsList ? this.purchaseGoodsList.map(item => {
          // status: 1-待开奖，2：未中奖，3：已中奖过 ，4-已兑奖，5-已退款，6-已兑换礼金，7-已过期
          item.createTime = item.createTime ? timeFormat(item.createTime).slice(5, 16) : ''
          item.pubTime = item.pubTime ? timeFormat(item.pubTime) : 0
          item.drawTime = item.pubTime.slice(11, 16)
          item.luckyDateValue = item.luckyDate ? (timeFormat(item.luckyDate).slice(0, 10)).replace(/-/g, '') : ''
        }) : []
        this.click = true
      }).catch(err => {
        console.info(err)
        this.isLogin()
        this.click = true
      })
    },
    /*
    抢购列表按钮事件
     */
    handleRecordClick (item) {
      this.recordId = item.id
      switch (item.status) {
        case 2:
          this.dialogType = 'dilateRefund'
          this.dialogVisible = true
          break
        case 1:
          if (item.codeList.length < item.codeLimit) {
            this.handleWatchVideo(item.id)
          }
          break
        case 3:
          //  立即领奖
          this.$h5AppLink('contactUs', {})
          break
        case 6:
          this.$router.push({
            name: 'tbCash',
            query: { token: localStorage.getItem('token') }
          })
          localStorage.setItem('navIndex', '2')
          break
      }
    },
    /*
    弹窗按钮提交
     */
    handleDialogSubmit () {
      if (this.dialogType == 'dilateRefund') {
        this.$axios.post(api.recordExchange, {
          id: this.recordId || this.lotteryInfo.recordId
        }).then(res => {
          this.dialogVisible = false
          this.recordPage = 0
          this.handleGetRecordList()
        }).catch(err => {
          this.isLogin()
          console.info(err)
        })
      } else if (this.dialogType == 'dilate') {
        this.handlePay(8)
      }
    },
    /*
    vip福利
    */
    handleVIPActivity (type) {
      switch (type) {
        case 1:
          //  页面重新渲染
          this.handleChangeTab('1')
          break
        case 2:
          this.$h5AppLink('openFreeActivity', {
            id: 2
          })
          break
        case 3:
          if (this.payOnePrivilege) {
            this.$router.push({
              name: 'phoneCoupon',
              query: {
                token: localStorage.getItem('token')
              }
            })
            localStorage.setItem('navIndex', '3')
          } else {
            this.dialogType = 'privilege'
            this.dialogVisible = true
          }
          break
        case 4:
          if (this.payOnePrivilege) {
            this.$router.push({
              name: 'tbCash',
              query: {
                token: localStorage.getItem('token')
              }
            })
            localStorage.setItem('navIndex', '3')
          } else {
            this.dialogType = 'privilege'
            this.dialogVisible = true
          }
          break
      }
    },
    /*
    离开一元购，查询判断弹窗类型
    */
    handlePreBack () {
      this.$axios(api.drawPreBack, {}).then(res => {
        this.dialogVisible = true
        switch (res.data.data.bizType) {
          case 0:
            this.dialogVisible = false
            break
          case 1:
            this.dialogType = 'more'
            break
          case 2:
            this.dialogType = 'zero'
            break
          case 3:
            this.dialogType = 'dilate'
            break
          case 4:
            this.dialogType = 'privilege'
            break
        }
      }).catch(err => {
        console.info(err)
      })
    },
    /*
    离开一元购，查询判断弹窗类型
    */
    handleClosePage () {
      this.$axios(api.drawPreBack, {}).then(res => {
        this.dialogVisible = true
        switch (res.data.data.bizType) {
          case 0:
            this.dialogVisible = false
            break
          case 1:
            this.dialogType = 'more'
            break
          case 2:
            this.dialogType = 'zero'
            break
          case 3:
            this.dialogType = 'dilate'
            break
          case 4:
            this.dialogType = 'privilegeClose'
            break
        }
      }).catch(err => {
        console.info(err)
      })
    },
    /*
    顶部按钮返回
    */
    back () {
      if (this.payOnePrivilege) {
        this.$h5AppLink('closePage', {})
      } else {
        this.handleClosePage()
      }
    },
    /*
    登录验证
     */
    isLogin () {
      const event = 'login'
      const param = {
        backUrl: `${baseUrl.pageUrl}/purchase`
      }
      this.$h5AppLink(event, param)
    },
    /*
    刷新页面
     */
    handleReload () {
      localStorage.removeItem('navIndex')
      this.$router.go(0)
    },
    /*
    头部返回事件
     */
    // goBack(){
    // }
    /*
    播放视频
     */
    handleJumpVideo () {
      this.playVideo = true
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .goods-info {
  .van-progress__pivot {
    display: none;
  }
}

/deep/ .van-dialog {
  overflow: visible;
}

/deep/ .lottery-content {
  .van-progress__portion {
    .van-progress__pivot {
      top: -12px;
    }
  }
}

.purchase {
  min-height: 100vh;

  .top {
    position: relative;
    line-height: 0;

    & > img {
      width: 100%;
    }

    .back-icon {
      position: absolute;
      top: 14px;
      left: 14px;
      font-size: 16px;
      color: #ffffff;
    }
  }

  .fixed-button {
    position: fixed;
    right: 0;
    top: 20px;
    display: flex;
    flex-direction: column;
    z-index: 99;

    img {
      width: 34px;
    }
  }

  .purchase-video {
    position: relative;
    top: -58px;
    text-align: center;

    & > img {
      width: 350px;
    }

    &-inner {
      position: absolute;
      top: 9px;
      left: 22px;

      & > img {
        width: 332px;
      }

      .video-click {
        margin: 18px auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 312px;
        height: 80px;
        background: linear-gradient(180deg, #FF5353 0%, #FF3333 100%);
        border-radius: 10px;

        & > p {
          font-size: 30px;
          color: #FFFFFF;
          font-weight: 800;
          line-height: 43px;
          margin-right: 15px;
        }

        .van-icon {
          font-size: 36px;
          color: #fff;
        }
      }

      .lottery-code {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 332px;
        height: 30px;
        background: #FFE8D0;
        border-radius: 14px;

        & > p {
          margin-left: 14px;
          font-size: 18px;
          font-weight: 800;
          color: #FF3B3C;
          line-height: 25px;
        }

        & > div {
          display: flex;
          align-items: center;

          p {
            font-size: 15px;
            font-weight: 400;
            color: #FF3B3C;
            line-height: 21px;
          }

          img {
            margin-right: 14px;
            margin-left: 6px;
            width: 22px;
          }
        }
      }

      .reward-ball {
        display: flex;
        margin-top: 26px;
        margin-left: -4px;

        & > div {
          position: relative;

          img {
            width: 48px;
            height: 48px;
          }

          p {
            position: absolute;
            top: 1px;
            left: 16px;
            font-size: 26px;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 42px;
          }
        }
      }

      .winning-detail {
        margin-top: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        & > p {
          font-size: 13px;
          color: #FF3B3C;
          line-height: 18px;
        }

        & > img {
          margin-left: 6px;
          width: 11px;
          height: 10px;
        }
      }
    }
  }

  .refund {
    margin-top: -38px;
    text-align: center;

    & > p {
      margin: 0 auto;
      width: 260px;
      height: 36px;
      background: linear-gradient(180deg, #FF6565 0%, #FF3535 100%);
      border-radius: 18px;
      font-size: 18px;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 36px;
    }
  }

  .goods-list {
    .goods-wrap {
      margin: 10px auto;
      display: flex;
      padding: 10px;
      width: 330px;
      height: 130px;
      background: #FFFFFF;
      border-radius: 10px;

      .goods-pic {
        position: relative;

        & > img {
          width: 130px;
          height: 130px;
          border-radius: 5px;
        }

        .open-time {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 110px;
          height: 24px;
          background: linear-gradient(180deg, #FFF4A8 0%, #FFDF2E 100%);
          border-radius: 22px 0px 10px 0px;

          p {
            margin-left: 4px;
            font-size: 13px;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
          }
        }
      }

      .goods-info {
        margin-left: 16px;
        text-align: left;

        .goods-title {
          font-size: 18px;
          font-weight: bold;
          color: #333333;
          line-height: 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        .goods-price {
          margin-bottom: 10px;
          text-align: center;
          width: 124px;
          height: 22px;
          background: #FFC5C5;
          border-radius: 4px;
          opacity: 0.5;
          font-size: 13px;
          color: #FF3B3C;
          line-height: 22px;
        }

        .van-progress {
          margin-bottom: 4px;
        }

        .amount {
          display: flex;

          & > p:first-child {
            margin-right: 26px;
          }

          & > p {
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            line-height: 17px;

            & > span {
              margin: 0 3px;
              color: #FF3B3C;
            }
          }
        }

        .joined-button {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 9px;
          width: 183px;
          height: 38px;
          background: #D8D8D8;
          border-radius: 19px;

          p {
            font-size: 16px;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 22px;
          }
        }

        .join-button {
          text-align: center;
          display: flex;
          align-items: center;
          margin-top: 9px;
          width: 183px;
          height: 38px;
          background: linear-gradient(180deg, #F74545 0%, #D92635 100%);
          border-radius: 19px;

          p {
            margin-left: 10px;
            font-size: 16px;
            font-weight: bold;
            color: #FFFFFF;
            line-height: 22px;

            span {
              margin-left: 4px;
              padding-right: 15px;
              font-size: 24px;
              font-weight: normal;
              color: #FFFFFF;
              line-height: 33px;
              border-right: 1px dotted #ffffff;
            }
          }

          p:last-child {
            margin-left: 20px;
          }
        }
      }
    }
  }

  .purchase-goods-list:last-child {
    margin-bottom: 50px;
  }

  .purchase-goods-list {
    margin-top: 12px;

    display: inline-block;
    width: 350px;
    background: #FFFFFF;
    border-radius: 10px;

    .head {
      margin: 14px 20px;
      display: flex;
      justify-content: space-between;

      span {
        font-size: 15px;
        font-weight: 400;
        color: #333333;
        line-height: 21px;
      }

      & > span:last-child {
        color: #FF3B3C;
      }
    }

    .content {
      display: flex;
      margin-left: 15px;

      & > img {
        width: 96px;
        height: 96px;
      }

      .info {
        margin-left: 20px;
        text-align: left;

        .title {
          margin-bottom: 10px;
          font-size: 18px;
          font-weight: bold;
          color: #333333;
          line-height: 25px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          -webkit-box-orient: vertical;
        }

        & > p {
          display: flex;
          font-size: 12px;
          font-weight: 400;
          color: #666666;
          line-height: 20px;

          & > span {
            display: block;
            content: " ";
            width: 10px;
          }
        }
      }
    }

    .code-list {
      margin-top: 12px;
      margin-left: 20px;
      padding-bottom: 12px;
      min-width: 310px;
      display: flex;
      border-bottom: 1px solid #E2E2E2;

      & > p {
        width: 70px !important;
        font-size: 12px;
        color: #333333;
        line-height: 20px;
      }

      & > div {
        display: flex;
        flex-wrap: wrap;
        width: 260px;
      }

      .code-wrap {
        margin-left: 8px;
        margin-bottom: 2px;
        width: 74px;
        height: 20px;
        background: #FFFDEA;
        border-radius: 2px;

        & > p {
          text-align: center;
          font-size: 13px;
          color: #C99040;
          line-height: 18px;
        }
      }
    }

    .footer-button {
      float: right;
      margin-top: 10px;
      margin-bottom: 12px;
      margin-right: 14px;

      .normal {
        font-size: 15px;
        color: #333333;
        line-height: 32px;
      }
    }

    .button-more {
      width: 107px;
      height: 32px;
      background: linear-gradient(180deg, #F74545 0%, #D92635 100%);
      border-radius: 20px;
      font-size: 15px;
      color: #FFFFFF;
      line-height: 32px;
    }

    .button-earn {
      width: 107px;
      height: 32px;
      background: linear-gradient(180deg, #FF8705 0%, #FE5002 100%);
      border-radius: 20px;
      font-size: 15px;
      color: #FFFFFF;
      line-height: 32px;
    }

    .button-recharge {
      width: 107px;
      height: 32px;
      border-radius: 20px;
      border: 1px solid #FF3B3C;
      font-size: 15px;
      color: #FF3B3C;
      line-height: 32px;
    }
  }

  .activity-list {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-bottom: 60px;

    & > div {
      position: relative;
      margin-top: 12px;
      line-height: 0;

      & > img {
        width: 350px;
        height: 158px;
        border-radius: 10px;
      }

      .activity-wrap {
        position: absolute;
        top: 35px;
        left: 150px;
        text-align: left;

        .title {
          font-size: 30px;
          font-weight: 800;
          color: #FFFFFF;
          line-height: 42px;
        }

        & > p {
          font-size: 15px;
          color: #FFFFFF;
          line-height: 21px;
        }
      }
    }
  }

  .purchase-default {
    text-align: center;

    & > img {
      margin-top: 70px;
      width: 140px;
      height: 134px;
    }

    & > p {
      margin-bottom: 24px;
      font-size: 15px;
      color: #999999;
      line-height: 21px;
    }

    .join-activity {
      margin: 50px auto 0;
      width: 260px;
      height: 50px;
      background: linear-gradient(180deg, #F74545 0%, #D92635 100%);
      box-shadow: 0px 2px 8px 0px #FE8D62;
      border-radius: 28px;
      font-size: 18px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 25px;
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 48px;
    background: #FFFFFF;
    box-shadow: 0px 2px 6px 0px rgba(51, 51, 51, 0.2);
    display: flex;
    align-items: center;
    justify-content: space-between;

    .footer-icon:first-child {
      margin-left: 40px;
    }

    .footer-icon:last-child {
      margin-right: 48px;
    }

    .footer-icon {
      text-align: center;

      .first-icon {
        width: 51px;
      }

      .second-icon,
      .third-icon {
        width: 28px;
      }

      p {
        font-size: 10px;
        font-weight: 400;
        line-height: 14px;
      }
    }
  }

  .fixed-top {
    position: fixed;
    bottom: 65px;
    right: 15px;
    width: 38px;
    height: 38px;
    background: #FFFFFF;
    border-radius: 19px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    font-size: 10px;
    font-weight: normal;
    color: #C99040;
    line-height: 12px;
    text-align: center;

    .van-icon {
      font-size: 14px;
      font-weight: 600;
      margin-top: 4px;
    }
  }

  .join-activity {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px;
    //width: 320px;
    //height: 420px;
    background: linear-gradient(180deg, #F5C600 0%, #F4823B 100%);
    border-radius: 16px;
    text-align: center;

    .inner {
      width: 306px;
      //height: 406px;
      background: #FCF2D6;
      border-radius: 16px;

      .van-button {
        margin-bottom: 14px;
        width: 260px;
        height: 50px;
        background: linear-gradient(180deg, #F74545 0%, #D92635 100%);
        box-shadow: 0px 2px 6px 0px #D41020;
        border-radius: 28px;
        font-size: 20px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 28px;
        border: none;
      }

      & > p {
        margin-bottom: 10px;
      }
    }

    .small-inner {
      height: 190px;

      .van-button {
        margin-top: 21px;
      }
    }

    p {
      font-size: 15px;
      font-weight: 400;
      color: #C99040;
      line-height: 21px;
    }

    .activity-info {
      .activity-title {
        margin-top: 16px;
        margin-bottom: 6px;
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
      }
    }

    .activity-pic {
      margin: 18px auto;

      img {
        width: 180px;
        height: 200px;
      }
    }

    .award-pic {
      margin: 10px auto 4px;

      img {
        width: 198px;
        height: 198px;
      }
    }

    .privilege-open {
      .title {
        margin-top: 16px;
        font-size: 20px;
        font-weight: bold;
        color: #C99040;
        line-height: 28px;
      }

      .icon-list {
        margin: 16px 24px;
        display: flex;
        justify-content: space-between;

        & > div {
          & > img {
            width: 70px;
            height: 70px;
          }

          & > p {
            font-size: 13px;
            color: #C99040;
            line-height: 18px;
          }
        }
      }

      .van-button {
        margin-bottom: 16px;
        width: 260px;
        height: 50px;
        background: linear-gradient(180deg, #F74545 0%, #D92635 100%);
        box-shadow: 0px 2px 8px 0px #FE8D62;
        border-radius: 28px;
        font-size: 20px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 50px;
        border: none;
      }

      & > p {
        margin-bottom: 16px;
        font-size: 15px;
        color: #C99040;
        line-height: 21px;
      }
    }

    .service-time {
      margin-bottom: 12px;
      font-size: 15px;
      color: #C99040;
      line-height: 21px;
    }
  }

  .dilate-packet {
    position: relative;
    text-align: center;

    .packet-title {
      width: 160px;
    }

    & > p {
      margin: 0 auto;
      width: 300px;
      font-size: 18px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 25px;
    }

    .packet-coupon {
      width: 300px;
    }

    .packet-text {
      position: absolute;
      top: 105px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      & > p {
        width: 320px;
        font-size: 15px;
        color: #C36202;
        line-height: 21px;
      }
    }

    .packet-button {
      position: absolute;
      left: 46px;
      bottom: 22px;

      .button {
        width: 228px;
        height: 50px;
        background: linear-gradient(180deg, #FFD65C 0%, #FFB500 100%);
        border-radius: 28px;
        display: flex;
        align-items: center;
        justify-content: center;

        & > p {
          font-size: 24px;
          font-weight: bold;
          color: #EC2630;
          line-height: 33px;
        }

        .van-icon {
          font-size: 20px;
          color: #EC2630;
        }
      }

      & > p {
        margin-top: 12px;
        font-size: 13px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 18px;
      }
    }
  }

  .open-lottery {
    position: relative;
    text-align: center;

    & > img {
      width: 322px;
    }

    .lottery-content {
      position: absolute;
      top: 96px;
      left: 20px;

      & > p {
        font-size: 15px;
        font-weight: 400;
        color: #FF3B3C;
        line-height: 21px;
      }

      .code-count {
        position: absolute;
        top: 64px;
        left: 133px;
        font-size: 30px;
        font-weight: 800;
        color: #FF3B3C;
        line-height: 42px
      }

      & > img {
        margin-top: 20px;
        margin-bottom: 20px;
        width: 192px;
        height: 58px;
      }

      .reward-ball {
        display: flex;
        justify-content: center;
        margin: 34px 0 42px;

        & > div {
          position: relative;

          img {
            width: 36px;
            height: 36px;
          }

          p {
            position: absolute;
            top: 0;
            left: 11px;
            font-size: 24px;
            font-weight: 600;
            color: #FFFFFF;
            line-height: 33px;
          }
        }
      }

      .van-progress {
        margin: 0 auto;
        width: 230px;
        height: 6px;
      }

      .lottery-button {
        margin-top: 52px;

        & > p {
          font-size: 15px;
          color: #FFD3A7;
          line-height: 21px;
        }

        .leave {
          margin-top: 10px;
        }

        .button {
          margin-top: 9px;
          width: 280px;
          height: 50px;
          background: linear-gradient(180deg, #FFF2A3 0%, #FFCE55 100%);
          box-shadow: 0px 2px 8px 0px #A50D15;
          border-radius: 28px;

          p {
            font-size: 24px;
            font-weight: normal;
            color: #DF2F32;
            line-height: 50px;
          }
        }
      }
    }

    .lottery-close {
      position: absolute;
      bottom: -77px;
      left: 142px;

      .van-icon {
        font-size: 38px;
        color: #ffffff;
      }
    }
  }
}
</style>
